// MainContent.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import bookCover from '../assets/images/anpebookcover.png';
import styles from './styles.module.css'

export default function MainContent() {
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        <Col lg={8} md={7}>
          <h1 className="display-4">African Nonism and Peomocracy</h1>
          <div className="lead">
            {/* A contemplative exploration into the realms of societal constructs. */}
            
            <p>African Nonism & Peomocracy: Embracing a New Governance Paradigm" introduces a revolutionary 
            governance model rooted in the autonomy and innovation of the Global African community. African 
            Nonism advocates for self-originated solutions, encouraging a unique path forward, distinct from 
            external influences. Peomocracy, born from this ethos, redefines governance by placing decision-making 
            power directly in the hands of the people, known as afholders. This system transcends traditional 
            political structures, fostering a participatory, collective, and direct approach to governance.</p>

             <p>The essence of African Peomocracy lies in the nurturing of human capital (Afpital) and the equitable 
             generation of value, wealth, and welfare. With a focus on transparency, integrity, and equality, 
             Peomocracy is not merely a governance model but a transformative approach to societal well-being. 
             "African Nonism & Peomocracy" invites readers to discover a visionary perspective on collective growth 
             and empowerment, offering a glimpse into a future where governance is synonymous with the prosperity and 
             welfare of all.</p>

              {/* <p>The book "African Nonism & Peomocracy" delves deep into these concepts, presenting a visionary perspective on governance, 
              wealth creation, and societal development. It's a narrative of empowerment, innovation, and collective growth, 
              inviting readers to explore the transformative potential of African Nonism and Peomocracy in shaping a 
              prosperous, inclusive, and well-governed community for generations to come. 
              Join us on this enlightening journey, and be part of the evolution towards a future where every voice matters, 
              and governance truly serves the welfare of all.</p> */}
          </div>
        </Col>
        <Col lg={4} md={5}>
        <Card className="shadow-lg mb-0 bg-white rounded">
          <div className={`${styles['book-cover']} shadow-lg mb-0 bg-white rounded`}>
            <Card.Img variant="top" src={bookCover} alt="Book Cover" className="book-cover-img rounded" style={{ marginTop: '-2px' }} />

          </div>
        </Card>
      </Col>


      </Row>
    </Container>
  );
}
