// src/components/VideoManager.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Container, Row, Col } from 'react-bootstrap';
import { projectFirestore } from '../firebase/config'; // Adjusted import
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const VideoManager = () => {
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({ videoname: '', videourl: '', id: null });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      const videosCollectionRef = collection(projectFirestore, 'videos');
      const videosSnapshot = await getDocs(videosCollectionRef);
      setVideos(videosSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchVideos();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editing) {
      const videoDocRef = doc(projectFirestore, 'videos', currentVideo.id);
      await updateDoc(videoDocRef, {
        videoname: currentVideo.videoname,
        videourl: currentVideo.videourl
      });
    } else {
      const videosCollectionRef = collection(projectFirestore, 'videos');
      await addDoc(videosCollectionRef, {
        videoname: currentVideo.videoname,
        videourl: currentVideo.videourl
      });
    }
    setEditing(false);
    setCurrentVideo({ videoname: '', videourl: '', id: null }); // Reset form
  };

  const handleEdit = (video) => {
    setEditing(true);
    setCurrentVideo(video);
  };

  const handleDelete = async (id) => {
    const videoDocRef = doc(projectFirestore, 'videos', id);
    await deleteDoc(videoDocRef);
    setVideos(videos.filter(video => video.id !== id)); // Update local state
  };

  const handleChange = (e) => {
    setCurrentVideo({ ...currentVideo, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <Row>
        <Col md={4}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Video Name</Form.Label>
              <Form.Control type="text" name="videoname" value={currentVideo.videoname} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Video URL</Form.Label>
              <Form.Control type="text" name="videourl" value={currentVideo.videourl} onChange={handleChange} />
            </Form.Group>
            <Button variant="primary" type="submit">
              {editing ? 'Update' : 'Submit'}
            </Button>
          </Form>
        </Col>
        <Col md={8}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Video Name</th>
                <th>Video URL</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {videos.map(video => (
                <tr key={video.id}>
                  <td>{video.videoname}</td>
                  <td>{video.videourl}</td>
                  <td>
                    <Button variant="info" onClick={() => handleEdit(video)}>Edit</Button>{' '}
                    <Button variant="danger" onClick={() => handleDelete(video.id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoManager;
