import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../assets/images/anplogo2.jpeg'; // Ensure the correct path to your logo
import styles from './Header.module.css';


const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="py-3">
      <LinkContainer to="/">
        <Navbar.Brand>
          <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto"> {/* ms-auto pushes the nav items to the right */}
          <LinkContainer to="/">
          <Nav.Link className={styles.navLinkLarge}>Home</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/video-series">
            <Nav.Link className={styles.navLinkLarge}>Video Series</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
