// import React, { useState, useEffect } from 'react';
// import { Table } from 'react-bootstrap';
// import { projectFirestore } from '../firebase/config'; // Ensure this points to your Firebase config
// import { collection, getDocs } from 'firebase/firestore';

// const VideoSeriesPage = () => {
//   const [videoSeries, setVideoSeries] = useState([]);

//   useEffect(() => {
//     const fetchVideos = async () => {
//       const videosCollectionRef = collection(projectFirestore, 'videos');
//       const videosSnapshot = await getDocs(videosCollectionRef);
//       setVideoSeries(videosSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
//     };

//     fetchVideos();
//   }, []);

//   return (
//     <div className="container mt-4">
//       <h2>Video Series</h2>
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>Video</th>
//             <th>Description</th>
//           </tr>
//         </thead>
//         <tbody>
//           {videoSeries.map((video, index) => (
//             <tr key={index}>
//               <td>
//                 <a href={video.videourl} target="_blank" rel="noopener noreferrer">
//                   {video.videoname}
//                 </a>
//               </td>
//               <td>{video.description}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default VideoSeriesPage;


import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { projectFirestore } from '../firebase/config'; // Ensure this points to your Firebase config
import { collection, getDocs } from 'firebase/firestore';

const VideoSeriesPage = () => {
  const [videoSeries, setVideoSeries] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const videosCollectionRef = collection(projectFirestore, 'videos');
      const videosSnapshot = await getDocs(videosCollectionRef);
      setVideoSeries(videosSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchVideos();
  }, []);

  // Function to extract video ID from YouTube URL
  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  return (
    <Container className="mt-4">
      <h2>Video Series</h2>
      <Row>
        {videoSeries.map((video, index) => {
          const videoID = getYouTubeID(video.videourl);
          const thumbnailUrl = videoID ? `https://img.youtube.com/vi/${videoID}/0.jpg` : '';
          return (
            <Col key={index} md={6} lg={4} className="mb-3">
              <Card>
                <a href={video.videourl} target="_blank" rel="noopener noreferrer">
                  <Card.Img variant="top" src={thumbnailUrl} alt={video.videoname} />
                </a>
                <Card.Body>
                  <Card.Title>{video.videoname}</Card.Title>
                  <Button variant="primary" href={video.videourl} target="_blank">Watch Video</Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default VideoSeriesPage;
