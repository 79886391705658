import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDjNsO6OoqRYHjFO2orRw1p0lQ01sxBxwM",
    authDomain: "africanpeomocracy.firebaseapp.com",
    projectId: "africanpeomocracy",
    storageBucket: "africanpeomocracy.appspot.com",
    messagingSenderId: "925678005271",
    appId: "1:925678005271:web:92adf82ece6fb3960cc052",
    measurementId: "G-BS19MCJ7YW"
  };

const app = initializeApp(firebaseConfig);

const projectAuth = getAuth(app);
const projectFirestore = getFirestore(app);
const projectStorage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
const projectFunctions = getFunctions(app);

export { projectAuth, projectFirestore, projectStorage, googleProvider, projectFunctions, serverTimestamp };


