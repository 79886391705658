// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-dark text-light py-4 mt-4">
      <Container>
        <Row>
          <Col lg={6} md={12} className="mb-4">
            <h5>African Nonism and Peomocracy</h5>
            <p>
              Exploring the rich tapestry of African philosophical and democratic thought.
            </p>
          </Col>
          <Col lg={3} md={6} className="mb-4">
            {/* <h5>Links</h5>
            <ul className="list-unstyled">
              <li><a href="#!" className="text-light">About</a></li>
              <li><a href="#!" className="text-light">Contact</a></li>
              <li><a href="#!" className="text-light">Support</a></li>
              <li><a href="#!" className="text-light">Privacy Policy</a></li>
            </ul> */}
          </Col>
          <Col lg={3} md={6} className="mb-4">
            <h5>Contact</h5>
            <ul className="list-unstyled">
              <li><a href="mailto:info@africannonismandpeomocracy.com" className="text-light">info@africannonismandpeomocracy.com</a></li>
              <li><a href="tel:+233 55 559 2621" className="text-light"> WhatsApp: +233 55 559 2621</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="text-center py-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © {year} African Nonism and Peomocracy
      </div>
    </footer>
  );
};

export default Footer;
